<template>
  <v-app app>
    <v-container fluid>
      <v-row class="my-5">
        <v-col align="center" xl="12" md="12">
            <v-img contain class="loginSyncioLogo" src="../assets/images/logo.png"></v-img> <h2 class="text-uppercase my-5">Syncio Admin Panel</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="center">
          <div v-show="errors">
            <v-alert v-for="(value, key) in errors" v-bind:key="key" class="error" width="600px" transition="fade-transition" dismissible>
              <span class="white--text">{{ value }}</span>
            </v-alert>
          </div>
          <v-card light width="600px" height="300px" v-if="loadingLogin"
            loading
            disabled
          >
            <v-card-title class="text-uppercase">Login</v-card-title>
            <v-container>
              <v-form @submit.prevent="onSubmit(email, password)">
                <v-text-field label="email" type="email" prepend-icon="mdi-email" v-model="email" required @change="$v.email.$touch()" :error-messages="checkEmailErrors"></v-text-field>
                <v-text-field label="password" type="password" prepend-icon="mdi-lock" v-model="password" required @change="$v.password.$touch()" :error-messages="checkPasswordErrors"></v-text-field>
                <v-btn type="submit" class="primary">LOGIN</v-btn>
              </v-form>
            </v-container>
          </v-card>
          <v-card light width="600px" height="300px" v-else>
            <v-card-title class="text-uppercase">Login</v-card-title>
            <v-container>
              <v-form @submit.prevent="onSubmit(email, password)">
                <v-text-field label="email" type="email" prepend-icon="mdi-email" v-model="email" required @change="$v.email.$touch()" :error-messages="checkEmailErrors"></v-text-field>
                <v-text-field label="password" type="password" prepend-icon="mdi-lock" v-model="password" required @change="$v.password.$touch()" :error-messages="checkPasswordErrors"></v-text-field>
                <v-btn type="submit" class="primary">LOGIN</v-btn>
              </v-form>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { LOGIN } from "@/store/actions.type";
import { UNSET_ERROR } from "@/store/mutations.type";
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: "Login",
  data() {
    return {
        email: null,
        password: null,
        loadingLogin: false,
    };
  },
  validations: {
    email: { required, email },
    password: { required },
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    checkEmailErrors () {
      const emailErrors = [];
      if (!this.$v.email.$dirty) return emailErrors
      !this.$v.email.email && emailErrors.push('Must be valid e-mail')
      !this.$v.email.required && emailErrors.push('E-mail is required')
      return emailErrors
    },
    checkPasswordErrors () {
      const passwordErrors = []
      if (!this.$v.password.$dirty) return passwordErrors
      !this.$v.password.required && passwordErrors.push('Password is required')
      return passwordErrors
    },
  },
  methods:{
    onSubmit(email, password){
      this.loadingLogin = true;
      this.$v.$touch();
      if (!this.$v.$invalid){
        this.$store.commit(`auth/${UNSET_ERROR}`);
        this.$store.dispatch(`auth/${LOGIN}`, {email, password})
            .then(({ data }) => {
              if (data.success) {
                this.$router.push({name: "Dashboard"})
              }
            })
            .finally(() => {
              this.loadingLogin = false;
            });
      }
    },
  }
}
</script>

<style scoped>
  .loginSyncioLogo{
    height: 70px;
    width: 70px;
  }
</style>